import React from "react";
import "./experience.css";


import C from "../../assets/c lan.png"
import { TiHtml5 } from "react-icons/ti";
import { IoLogoHtml5 } from "react-icons/io5";
import { IoLogoCss3 } from "react-icons/io5";
import { DiJavascript1 } from "react-icons/di";
import { SiTypescript } from "react-icons/si";
import { IoLogoReact } from "react-icons/io5";
import { IoLogoAngular } from "react-icons/io5";
import { FaJava } from "react-icons/fa6";
import { FaNodeJs } from "react-icons/fa";
import { SiExpress } from "react-icons/si";
import { SiPython } from "react-icons/si";





const Experience = () => {
  return (
    <section id="experience">
      <h5>Skill I have</h5>
      <h2>My Experience</h2>

      <div className="container experience_container">
        <div className="experience_frontend">
          <h3>Frontend Development</h3>
          <div className="experience_content">
            <article className="experience_details">
              <IoLogoHtml5 />
              <p>HTML</p>
            </article>

            <article className="experience_details">
            <IoLogoCss3 />
              <p>CSS</p>
            </article>

            <article className="experience_details">
              <DiJavascript1  />
              <p>JavaScript</p>
            </article>

            <article className="experience_details">
              <SiTypescript  />
              <p>TypeScript</p>
            </article>

            <article className="experience_details">
              <IoLogoReact  />
              <p>React</p>
            </article>

            <article className="experience_details">
              <IoLogoAngular  />
              <p>Angular</p>
            </article>
          </div>
        </div>

        <div className="experience_backend">
          <h3>Backend Development</h3>
          <div className="experience_content">

            <article className="experience_details">
              <FaNodeJs  />
              <p>Node JS</p>
            </article>

            <article className="experience_details">
              <SiExpress  />
              <p>Express JS   </p>
            </article>

            <article className="experience_details">
              <FaJava  />
              <p>Java</p>
            </article>

            <article className="experience_details">
              <TiHtml5 />
              <p>C</p>
            </article>

            <article className="experience_details">
              <SiPython  />
              <p>Python</p>
            </article>

          </div>
        </div>

        {/* <div className="experience_frontend">
          <div className="experience_content">
            <article className="experience_details">
              <IoLogoHtml5 />
              <p>HTML</p>
            </article>

            <article className="experience_details">
              <TiHtml5 />
              <p>CSS</p>
            </article>

            <article className="experience_details">
              <TiHtml5 />
              <p>JavaScript</p>
            </article>

            <article className="experience_details">
              <TiHtml5 />
              <p>TypeScript</p>
            </article>

            <article className="experience_details">
              <TiHtml5 />
              <p>React</p>
            </article>

            <article className="experience_details">
              <TiHtml5 />
              <p>Angular</p>
            </article>
          </div>
        </div> */}


      </div>
      <div className="space"></div>
    </section>
  );
};

export default Experience;
